/* Provide sufficient contrast against white background */
body {
    min-height: 100vh;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.heading {
    background-color: steelblue;
    padding: 0.1rem;
    color: white;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

/*LAYOUT.JS CSS*/
.page {
    padding: 0.5rem;
}